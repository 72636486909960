
.main-container{
    display: flex;

    .name-label{
        padding-top: calc(0.375rem + 1px);
        padding-bottom: 0px;
        margin-bottom: 0;
        font-size: inherit;
        line-height: 1.5;
    }
    .checkbox-container{
        text-align: center;
        border: 1px solid lightgrey;
        border-radius: 17px;
    }
    .btn-container{
        text-align: center;
        .btn{
            width: 200px;
        }
    }
    .type-ahead-search-icon {
        position: absolute;
        margin-top: 10px;
        z-index: 1;
        color: grey;
        margin-left: 10px;
    }
    .search-domains input{
        padding-left: 35px;
    }
    .header{
        margin-bottom: 30px;
        .checkbox-main-div{
            text-align: end;
            .checkbox{
                margin-top: 22px;
                margin-left: 5px;
            }
        }
    }

    .engage-domain{
        position: absolute;
        right: 30px;
        top: 30px;
    }

    .mg-20{
        margin-left: 20px;
    }
    .check-all-provision{
        right: 0px;
        top: 20px;
        position: absolute;
    }
    .data-table-icon{
        cursor: pointer;
    }

    .domain-level-toggles{
        text-align: center;
    }

    .domain-level-toggles i{
        cursor: pointer;
    }
}

.react-confirm-alert-overlay{
    z-index: 2000;
}