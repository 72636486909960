
.main-container{
    display: flex;

    .name-label{
        padding-top: calc(0.375rem + 1px);
        padding-bottom: 0px;
        margin-bottom: 0;
        font-size: inherit;
        line-height: 1.5;
    }
    .domain-checkbox-container{
        text-align: center;
        border: 1px solid lightgrey;
        border-radius: 17px;
        padding: 10px 20px;
        margin: 10px;
    }
    .btn-container{
        text-align: center;
        .btn{
            width: 200px;
        }
    }
    .domain-checkbox-container{
        .checkbox label::before{
            margin-top: 15px
        }
    
        p{
            border-top: 1px solid lightgrey;
            margin-top: 5px;
            padding-top: 5px;
        }
    }
    

}