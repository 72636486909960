.MainIndex{
    width: 100%;
    padding-right: 70px;

    .data-table-icon{
        font-size: 20px;
        margin-left: 10px;
        cursor: pointer;
    }
    /* Hiding id (1)  in exports servers table  */
    .users-table .mdb-dataTable  thead tr th:nth-child(1)
    {
        display: none;
    }

    .users-table .mdb-dataTable  tbody tr td:nth-child(1)
    {
        display: none;
    }

    .table-btn{
        margin-right: 12px;
    }
}

.main-container{
    .btn-width{
        margin-right: 15px;
    }
    .active-radios{
        margin-top: 10px;
    }
    .active-radios .margin-left{
        margin-left: 10px;
    }

    .order-component{
        border: 1px solid lightgrey;
        border-radius: 8px;
        padding: 20px;
        margin-left: 2px !important;
        margin-right: 0px !important;
    }
    
    .order-component select{
        margin-top: 29px;
        max-width: 95px;
    }
    
    .order-component button{
        margin-top: 29px;
        width: 130%;
    }

    .order-component .btn-width{
        width: 100%;
    }
    
    .order-component .col-md-custom{
        margin-right: 15px;
    }

    .margin-left-10{
        margin-left: 10px;
    }
    
    .order-component button {
        padding: 6px 12px;
    }
}   